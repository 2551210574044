import heroImage from '../images/alien-pods.jpg';
import heroImageTwo from '../images/alien-city.jpg';
import {CountDownClock} from './Countdown';
import styled from 'styled-components';


function Hero() {
    return (
    <HeroContainer>
        <CountDownClock />
        <PodsImage src={heroImage}/>
    </HeroContainer>
    );
  }
  
export default Hero;

const HeroContainer = styled.div`
    position: relative;
    > div {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: 768px){
                position: relative;
            }
    }
`;
const PodsImage = styled.img`
    margin: 0 auto;
`;
  