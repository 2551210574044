import styled from 'styled-components';
import alienlanguage from '../images/alienlanguage.svg';
import species from '../images/species.svg';
import numberpad from '../images/number-pad.png';
import goldpad from '../images/gold-pad.png';
import xlians from '../images/xlians.png';
import belrogs from '../images/belrogs.png';
import yuzis from '../images/yuzis.png';
import kilans from '../images/kilans.png';
import genjis from '../images/genjis.png';

function Species() {
    return (
     <AlienSpecies>
         <img className='alien-language' src={alienlanguage} alt="Alien Language" />
         <div className="headline">
            <h2>Spec<span>i</span>es</h2>
            <img src={species} alt="Species"/>
         </div>

         <div className="copy">
             <p>There are five species trapped inside the pods. 2,000 Xlians, 1,500 Belrogs, 1,000 Kilans, 495 Yuzis, and 5 Genjis with a total of 5,000 Aliens. The Xilans are a common species, and the Genjis are very rare.</p>
         </div>

         <ul className="species">
                 <li>
                     <div className="specie-image">
                        <img src={xlians} alt="Xlians" />
                     </div>
                     <div className="specie-info">
                     <img className='pad' src={numberpad} alt="Number Pad" />
                         <div className="info-wrapper">
                            <h3 className="specie-name">Xlians</h3>
                            <div className="specie-quantity">Quantity: <b>2,000</b></div>
                         </div>
                     </div>
                 </li>
                 <li>
                     <div className="specie-image">
                        <img src={belrogs} alt="Belrogs" />
                     </div>
                     <div className="specie-info">
                     <img className='pad' src={numberpad} alt="Number Pad" />
                         <div className="info-wrapper">
                            <h3 className="specie-name">Belrogs</h3>
                            <div className="specie-quantity">Quantity: <b>1,500</b></div>
                         </div>
                     </div>
                 </li>
                 <li>
                     <div className="specie-image">
                        <img src={kilans} alt="Kilans" />
                     </div>
                     <div className="specie-info">
                     <img className='pad' src={numberpad} alt="Number Pad" />
                         <div className="info-wrapper">
                            <h3 className="specie-name">Kilans</h3>
                            <div className="specie-quantity">Quantity: <b>1,000</b></div>
                         </div>
                     </div>
                 </li>
                 <li>
                     <div className="specie-image">
                        <img src={yuzis} alt="Yuzis" />
                     </div>
                     <div className="specie-info">
                     <img className='pad' src={numberpad} alt="Number Pad" />
                         <div className="info-wrapper">
                            <h3 className="specie-name">Yuzis</h3>
                            <div className="specie-quantity">Quantity: <b>495</b></div>
                         </div>
                     </div>
                 </li>
                 <li className='genjis'>
                     <div className="specie-image">
                        <img src={genjis} alt="Genjis" />
                     </div>
                     <div className="specie-info">
                         <div className="info-wrapper">
                            <h3 className="specie-name">Genjis</h3>
                            <div className="specie-quantity">Quantity: <b>5</b></div>
                            <p>No one knows how or why these Aliens skins are pure gold. These ultra-rare Aliens are tough to find.</p>
                         </div>
                         <img className='pad' src={goldpad} alt="Number Pad" />
                     </div>
                 </li>
             </ul>
    
     </AlienSpecies>
    );
  }
  
  export default Species;

  const AlienSpecies = styled.div`
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 37%, rgba(59,140,21,1) 100%);  
    position: relative;
    padding: 150px 0;
    @media (max-width: 768px){
        padding: 35px 15px;
    }

    .alien-language {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -65px;
    }

    .headline {
        text-align: center;
        h2 {
            color: #B5E8DD;
            font-family: 'Oswald', sans-serif;
            text-transform: uppercase;
            font-size: 6em;
            font-weight: 300;
            padding: 35px 0 0;
            span {
                color: #65ED26; 
            }   
        }
        img {
            display: block;
            margin: 0 auto;
        } 
    }

    .copy {
        color: #fff;
        max-width: 500px;
        text-align: center;
        font-size: 20px;
        margin: 35px auto 0;
    }
 


    .species {
        display: flex;
        flex-wrap: wrap;
        max-width: 960px;
        margin: 35px auto;
        padding: 0;
        @media (max-width: 768px){
                margin: 80px auto;
            }
        li {
            width: 25%;
            padding: 10px;
            border-radius: 8px;
            color: #fff;
            list-style: none;
            @media (max-width: 768px){
                width: 50%;
            }
            .specie-info {
                display: flex;
                justify-content: center;
                padding: 20px 5px;
                background: #36423F;
                border-radius: 0 0 8px 8px;
                align-items: center;
                .info-wrapper {
                    margin: 0 15px;
                    h3 {
                        font-size: 24px;
                        @media (max-width: 768px){
                            font-size: 20px;
                        }
                    }
                }
                .pad {
                    max-width: 60px;
                }
            }  
            &.genjis {
                width: 100%;
                display: flex;
                margin-top: 10%;
                @media (max-width: 768px){
                    flex-direction: column;
                }
                .specie-info {
                    width: 50%;
                    align-items: center;
                    border-radius: 0 8px 8px 0;
                    @media (max-width: 768px){
                        width: 100%;
                        border-radius: 0 0 8px 8px;
                    }
                    p {
                        margin: 15px 0;
                    }
                    .pad {
                        align-self: flex-start;
                        margin: 10px;
                    }
                }
                .specie-image {
                    width: 50%;
                    height: auto;
                    border-radius: 8px 0 0 8px;
                    @media (max-width: 768px){
                        width: 100%;
                        border-radius: 8px 8px 0 0;
                    }
                    img {
                        max-width: 338px;
                        margin-top: -25%;
                    }
                }
            }
            .specie-image {
                height: 224px;
                background: rgb(18,38,34);
                background: linear-gradient(0deg, rgba(18,38,34,1) 0%, rgba(181,232,221,1) 100%);
                border-radius: 8px 8px 0 0;
                display: flex;
                align-items: flex-end;
                @media (max-width: 768px){
                    height: auto;
                }
                img {
                    max-width: 224px;
                    margin: 0 auto;
                    @media (max-width: 768px){
                        width: 100%;
                    }
                }
            
            
        }
    }


    
`