import store from "../store";
import {getEthereumProvider} from "../../utils/EtheriumHelper";
import Web3EthContract from "web3-eth-contract";

const DATA_REQUEST_INITIALIZED = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const DATA_REQUEST_SUCCESS = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const DATA_REQUEST_FAILED = (errorMessage) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: errorMessage,
  };
};

export const UPDATE_TOTAL_SUPPLY = currentTokenID => ({
  type: "UPDATE_TOTAL_SUPPLY",
  payload: { currentTokenID }
});

export const fetchTotalSupply = () => async (dispatch) => {
  dispatch(DATA_REQUEST_INITIALIZED());
  try {
    let { smartContract, config, abi } = store.getState().blockchain;
    // Getting the Smart Contract in case the wallet isn't connected
    if (! smartContract) {
      const ethereum = getEthereumProvider();
      if (ethereum && ethereum.isMetaMask && config && abi) {
        Web3EthContract.setProvider(ethereum);
        const networkId = await ethereum.request({
          method: "net_version",
        });
        if (networkId == config.NETWORK.ID) {
          smartContract = new Web3EthContract(
            abi,
            config.CONTRACT_ADDRESS
          );
        }
      }
    }

    if (smartContract) {
      let currentTokenID = await smartContract.methods
        .getTotalSupply()
        .call();
      // let cost = await smartContract.methods.cost().call();

      dispatch(
        DATA_REQUEST_SUCCESS({
          currentTokenID,
          // cost,
        })
      );
    }
    else {
      dispatch(
        DATA_REQUEST_SUCCESS({
          currentTokenID: 0,
        })
      );
    }
  } catch (err) {
    console.log(err);
    dispatch(DATA_REQUEST_FAILED("Could not load data from contract."));
  }
};
