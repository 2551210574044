import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';

function importAll(r) {
    let images = {};
        r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images
   }

   const images = importAll(require.context('../images/preview', false, /\.(png|jpe?g|svg)$/));


function CollectionPreview() {
    var settings = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 10,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              },
            },
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                },
              }
        ]
        
      };
      return (
        <Preview>
            <Slider {...settings}>
                <img src={images['alien1.png'].default} alt="Alien Fam"/>
                <img src={images['alien2.png'].default} alt="Alien Fam"/>
                <img src={images['alien3.png'].default} alt="Alien Fam"/>
                <img src={images['alien4.png'].default} alt="Alien Fam"/>
                <img src={images['alien5.png'].default} alt="Alien Fam"/>
                <img src={images['alien6.png'].default} alt="Alien Fam"/>
                <img src={images['alien7.png'].default} alt="Alien Fam"/>
                <img src={images['alien6.png'].default} alt="Alien Fam"/>
                <img src={images['alien8.png'].default} alt="Alien Fam"/>
                <img src={images['alien9.png'].default} alt="Alien Fam"/>
                <img src={images['alien10.png'].default} alt="Alien Fam"/>
                <img src={images['alien11.png'].default} alt="Alien Fam"/>
            </Slider>
        </Preview>
      );
    }
export default CollectionPreview; 

const Preview = styled.div`
    position: relative;
    z-index: 10;
    margin-bottom: -8px;
    pointer-events: none;
    box-shadow: 0 -35px 34px #000;
`