import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import { fetchTotalSupply } from "../data/dataActions";
import { getEthereumProvider } from "../../utils/EtheriumHelper";

const UPDATE_CONFIG = payload => ({
  type: "BLOCKCHAIN_UPDATE_CONFIG",
  payload
});

export const fetchConfig = () => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const fetchConfig = fetch("/config/config.json", { headers });
  const fetchAbi = fetch("/config/abi.json", { headers });
  const [config, abi] = await Promise.all([fetchConfig, fetchAbi]).then(async value => {
    const [configResponse, abiResponse] = value;
    return [await configResponse.json(), await abiResponse.json()];
  })

  dispatch(UPDATE_CONFIG({ config, abi }));
};

const CONNECTION_INITIALIZED = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const CONNECTION_SUCCESS = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const CONNECTION_FAILED = (errorMessage) => {
  return {
    type: "CONNECTION_FAILED",
    payload: errorMessage,
  };
};

const UPDATE_WALLET_ACCOUNT = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connectWallet = (config, abi) => async (dispatch) => {
  dispatch(CONNECTION_INITIALIZED());
  const ethereum = getEthereumProvider();
  const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
  if (metamaskIsInstalled) {
    Web3EthContract.setProvider(ethereum);
    let web3 = new Web3(ethereum);
    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      const networkId = await ethereum.request({
        method: "net_version",
      });
      if (networkId == config.NETWORK.ID) {
        const SmartContractObj = new Web3EthContract(
          abi,
          config.CONTRACT_ADDRESS
        );
        dispatch(
          CONNECTION_SUCCESS({
            account: accounts[0],
            smartContract: SmartContractObj,
            web3: web3,
          })
        );

        /* Listeners on the Ethereum provider */
        ethereum.on("accountsChanged", (accounts) => {
          dispatch(updateWalletAccount(accounts[0]));
        });
        ethereum.on("chainChanged", () => {
          window.location.reload();
        });
      } else {
        dispatch(CONNECTION_FAILED(`Change network to ${config.NETWORK.NAME}.`));
      }
    } catch (err) {
      dispatch(CONNECTION_FAILED("Something went wrong."));
    }
  } else {
    dispatch(CONNECTION_FAILED("Install Metamask."));
  }
};

export const updateWalletAccount = (account) =>  async (dispatch) => {
  dispatch(UPDATE_WALLET_ACCOUNT({ account: account }));
};

export const updateTotalSupply = () => async (dispatch) => {
  dispatch(fetchTotalSupply());
};