import './index.css';
import styled from 'styled-components';
import Navigation from "./components/Navigation";
import Hero from './components/Hero';
import Story from "./components/Story";
import Species from "./components/Species";
import RoadMap from "./components/RoadMap";
import CollectionPreview from "./components/CollectionPreview";
import Mint from './components/Mint';
import Dashboard from './components/Dashboard';

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path={''} element={<HomeScreen/>}/>
      <Route path={'/mint'} element={<Mint/>}/>
      <Route path={'/dashboard'} element={<Dashboard/>}/>
    </Routes>
  );
}

function HomeScreen(){
  return(
    <MotherShip>
      <Navigation />
      <Hero/>
      <Story />
      <CollectionPreview />
      <Species />
      <RoadMap />
    </MotherShip>
  )
}

export default App;

const MotherShip = styled.div`
  background: #000;
`


