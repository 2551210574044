import styled from 'styled-components';
import alienfamsvg from '../images/alienfam.svg';
import alienbaby from '../images/baby-alien.png';
import lab from '../images/underground-lab.jpg';
import planetwater from '../images/planet-water.png';
import escapeday from '../images/escape-day.jpg';

function Story() {
    return (
        <AlienFamStory>
        <div className="copy">
        <div className="inner-copy">
            <div className="headline">
                <h1>Al<span>i</span>en Fam</h1>
                <img src={alienfamsvg} alt="Alien Fam" />
            </div>
            <p>Thousands of years ago, a community of Aliens moved to planet Earth looking to help the planet. For the 3rd time, planet Earth was destroyed by humans, and this time the Alien Fam decided to help by sharing knowledge about the universe and technology. They called planet Earth "<img src={planetwater} alt="Planet Water"/>" which means "Planet Water" in their language.</p>
            <p>The Alien Fam co-existed with humans for thousands of years, and planet Earth became one of the 33 wonders of the Milky Way Universe. Humans were delighted with the evolution and all the new technology they could create for the future.</p>
            </div>
        </div>

        <div className="copy right">
            <img className="alienbaby" src={alienbaby} alt="" />
            <div className="inner-copy">
                <p>One day the Alien Fam decided that it was time to go back to their planet, and a group of humans didn't want them to go because they wanted more advanced technology and more information about the universe. The group of angry armed humans captured 5,000 Aliens, and the rest escaped.</p>

                <p>For generations, the family of 5,000 Aliens had been stuck inside an underground top-secret military base. They are paralyzed inside water pods running experiments on them every day. They can hear, feel and communicate with the other Aliens with their minds.</p>

                <p>One day, they heard the alarm, and soldiers were running everywhere looking for someone or something. It seems like one of the Aliens escaped, but all the 5,000 Aliens were still trapped inside the pods. One of the Aliens saw something getting out of one of the air conditioning vents, and it was a one-foot-tall grey baby Alien. They both looked in their eyes and felt the pain, and the baby Alien, with closed eyes, did something with both hands, and all the pod's glass exploded, releasing all the Aliens.</p>
            </div>
        </div>

        <div className="copy center">
            <div className="inner-copy">
                <p>All the 5,000 Aliens escape the military base, but they need help from the good humans to blend in and stay out of trouble. They love art, video games, fashion, music, and more.</p>
                <p>Let's get the family back together.</p>
            </div>
        </div>

        <img className='escape-day' src={escapeday} alt="Escape Day"/>

        </AlienFamStory>
    );
  }
  
  export default Story; 

  const AlienFamStory = styled.div`
    padding: 100px 15px 0;
    color: #fff;
    background-image: url(${lab});
    background-repeat: no-repeat;
    background-size: 1400px;
    background-position: top center;

    h1 {
        color: #B5E8DD;
        font-family: 'Oswald', sans-serif;
        text-transform: uppercase;
        font-size: 6em;
        font-weight: 300;
        span{
            color: #65ED26;
        }
        @media (max-width: 768px){
            font-size: 5em
        }
    }
    .headline {
        margin-bottom: 35px;
    }

    p {
        margin: 15px 0;
        font-size: 20px;
    }

    .copy {
        position: relative;
        display: flex;
        max-width: 1100px;
        margin: 0 auto;
        &.right {
            justify-content: flex-end;
            @media (max-width: 768px){
                flex-direction: column;
            }
        }
        &.center {
            text-align: center;
            flex-direction: column;
            align-items: center;
            margin-top: 35px;
        }
        p {
            position: relative;
            z-index: 1;
            img {
                max-width: 200px;
                display: inline;
            }
        }
        .alienbaby {
            position: absolute;
            left: -250px;
            top: -70px;
            max-width: 900px;
            @media (max-width: 768px){
                position: relative;
                left: 0;
                top: 0;
                width: 100%;
            }
        }
        .inner-copy {
            width: 45%;
            @media (max-width: 768px){
                width: 100%;
                background: rgba(0,0,0,0.7);
                padding: 15px;
            }
        }
    }
    .escape-day {
            margin: 100px auto 0;
        }
 
    
  `