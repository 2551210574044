import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {connectWallet, fetchConfig, updateTotalSupply} from "../redux/blockchain/blockchainActions";
import { fetchTotalSupply } from "../redux/data/dataActions";
import { getPresale } from "../utils/getPresaleStatus";
import { getPause } from "../utils/getPauseStatus";
import { handler } from "../api/index";
import styled from "styled-components";
import pods from '../images/alien-pods-3.jpg';
import insidePod from '../images/inside-the-pod.gif';
import loading from '../images/loading.svg';
import Navigation  from "./Navigation";

import "../style/style.css";

export default function Mint() {
  const dispatch = useDispatch();
  const { config, abi, account, smartContract, web3, errorMsg} = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);

  useEffect(() => {
    if (!config || !abi) {
      dispatch(fetchConfig());
    }
  }, [config, abi]);

  useEffect(() => {
    dispatch(fetchTotalSupply());
  }, [account, config, web3]);

  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);

  const claimNFTs = () => {
    let cost = config.WEI_COST;
    let gasLimit = config.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${config.NFT_NAME}...`);
    setClaimingNft(true);
    smartContract.methods
      .publicMint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: config.CONTRACT_ADDRESS,
        from: account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        try {
          var lines = err?.message.split("\n");
          const tx = lines[12].slice(22, 88);
          getRevertReason(tx);
          setClaimingNft(false);
        } catch (err) {
          console.log(err);
          setFeedback("The transaction has been cancelled!");
          setClaimingNft(false);
        }
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Success! Welcome to the Alien Fam.`
        );
        setClaimingNft(false);
        dispatch(fetchTotalSupply(account));
      });
  };

  const claimPreNFTs = () => {
    let cost = config.PRE_WEI_COST;
    let gasLimit = config.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${config.NFT_NAME}...`);
    setClaimingNft(true);
    smartContract.methods
      .whitelistMint(mintAmount, proof)
      .send({
        gasLimit: String(totalGasLimit),
        to: config.CONTRACT_ADDRESS,
        from: account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        try {
          var lines = err?.message.split("\n");
          const tx = lines[12].slice(22, 88);
          getRevertReason(tx);
          setClaimingNft(false);
        } catch (err) {
          console.log(err);
          setFeedback("The transaction has been cancelled!");
          setClaimingNft(false);
        }
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Success! Welcome to the Alien Fam.`
        );
        setClaimingNft(false);
        dispatch(fetchTotalSupply(account));
      });
  };

  const getRevertReason = async (txHash) => {
    const val = await web3.eth.getTransaction(txHash);
    try {
      var err = await web3.eth.call(val);
      console.log("dsd", err);
    } catch (err) {
      const firstLine = err?.message.match(/^.*$/m)[0];
      const errMsg = firstLine.replace("execution reverted: ", "");
      setFeedback(errMsg);
    }
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const [proof, setProof] = useState(null);
  useEffect(() => {
    handler(account).then(setProof);
  }, [account]);

  const [pause, setPause] = useState();
  useEffect(() => {
    getPause().then((val) => setPause(val));
  }, []);

  const [preSale, setPreSale] = useState();
  useEffect(() => {
    getPresale().then((val) => setPreSale(val));
  }, []);


  return (
    <MintContainer>
      <Navigation />
      <div className="Mint">

        { pause ? (
          <div className="Mint_Con">
            <h2 className="mint-alert">The Mint is temporarily paused!</h2>
          </div>
        ) : (

          <>
            { preSale ? (
              <div className="Mint_Con">
                {claimingNft ? <img className="inside-pod" src={loading} alt="Minting"/> : <img className="inside-pod" src={insidePod} alt="Release the Alien inside the Pod"/>}
                <h2>
                  {data.currentTokenID} / {config.MAX_SUPPLY}
                </h2>
                <h3>
                  1 NFT costs {config.PRE_DISPLAY_COST} {config.NETWORK.SYMBOL}
                </h3>
                <h4 className="mint-alert">
                  {account != null && proof == null
                    ? "Your wallet address is not in the whitelist"
                    : null}
                </h4>
                { account === "" ||
                smartContract === null ||
                proof == null ? (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connectWallet(config, abi));
                      }}
                    >
                      CONNECT WALLET
                    </button>
                    {errorMsg !== "" ? (
                      <>
                        <h4 className="mint-alert">{errorMsg}</h4>
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    <h3 className="mint-alert">{feedback}</h3>
                    <div className={claimingNft ? "minting" : ''}>
                      <button className="mintbtn-decrease"
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </button>
                      <button className="mintbtn"
                        onClick={(e) => {
                          e.preventDefault();
                          claimPreNFTs();
                        }}
                      >
                        {claimingNft ? `MINTING` : `MINT ${mintAmount}`}
                      </button>
                      <button className="mintbtn-increase"
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </button>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="Mint_Con">
                 {claimingNft ? <img className="inside-pod" src={loading} alt="Minting"/> : <img className="inside-pod" src={insidePod} alt="Release the Alien inside the Pod"/>}
                <h2>
                  {data.currentTokenID} / {config?.MAX_SUPPLY}
                </h2>
                <h3>
                  1 Alien Fam costs {config?.DISPLAY_COST} {config?.NETWORK?.SYMBOL}
                </h3>
                {account === "" ||
                smartContract === null ? (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connectWallet(config, abi));
                      }}
                    >
                      CONNECT WALLET
                    </button>
                    {errorMsg !== "" ? (
                      <>
                        <h4 className="mint-alert">{errorMsg}</h4>
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    <h3 className="mint-alert">{feedback}</h3>
                    <div className={claimingNft ? "minting" : ''}>
                      <button className="mintbtn-decrease"
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </button>
                      <button className="mintbtn"
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                        }}
                      >
                        {claimingNft ? "MINTING" : `MINT ${mintAmount}`}
                      </button>
                      <button className="mintbtn-increase"
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}



      </div>


    </MintContainer>
  );
}

const MintContainer = styled.div`
  background-image: url(${pods});
  backgrouns-size: cover;
  background-position: center;
  .inside-pod {
    position: relative;
    margin-top: -80px;
    max-width: 200px;
    border-radius: 12px;
  }
  .mint-alert {
    color: #7cc381;
    font-weight: normal;
    font-size: 2em;
    background: #000;
    padding: 15px 25px;
    margin: 25px 0;
    border-radius: 12px;
    &:empty {
      display: none;
    }
  }
  .minting {
    opacity: 0.2;
    pointer-events: none;
  }

`;

