import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';

import addreses from './whitelist.json';

export async function handler(address) {
    const hashedAddresses = addreses.map(addr => keccak256(addr));
    const merkleTree = new MerkleTree(hashedAddresses, keccak256, { sortPairs: true });
    
    const hashedAddress = keccak256(address);
    const proof = merkleTree.getHexProof(hashedAddress);

    return proof.length ? proof : null;
}