import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {connectWallet, fetchConfig} from "../redux/blockchain/blockchainActions";
import shirtIcon from "../images/shirt-solid.svg";
import giveawaysIcon from "../images/party-horn-solid.svg";
import airdropsIcon from "../images/gifts-solid.svg";
import vipIcon from "../images/shield-keyhole-solid.svg";
import styled from "styled-components";
import Token from "../token/token";
import Navigation from "./Navigation";
import ReactTooltip from 'react-tooltip';

import "../style/style.css";

export default function Dashboard() {
  const [image, setImage] = useState([]);
  const [count, setCount] = useState(null);

  const dispatch = useDispatch();
  const {account, config, abi, web3, errorMsg} = useSelector((state) => state.blockchain);

  useEffect(() => {
    if (!config || !abi) {
      dispatch(fetchConfig());
    }
  }, [config, abi]);

  useEffect(() => {
    if (account) {
      Load().then(() => null);
    }
  }, [account]);

  const Load = async () => {
    const contract = new web3.eth.Contract(Token, config.CONTRACT_ADDRESS)
    const walletAddress = account;
    // const walletAddress = document.getElementById("wallet_address").value;
    // const walletAddress = "0x54E903B3DB02987c96dCD019845147df47c0462a";
    contract.defaultAccount = walletAddress
    const spacePunksBalance = await contract.methods.walletOfOwner(walletAddress).call();

    const images = [];
    for (let spacePunk of spacePunksBalance) {
      let tokenMetadataURI = await contract.methods.tokenURI(spacePunk).call()
      tokenMetadataURI = `https://ipfs.io/ipfs/${tokenMetadataURI.split("ipfs://")[1]}`

      const tokenMetadata = await fetch(tokenMetadataURI).then((response) => response.json())
      console.log(tokenMetadata);
      images.push(tokenMetadata);
    }
    setCount(spacePunksBalance?.length)
    setImage(images)
  }

  const [show, setShow] = useState(false);
  const [detail, setDetail] = useState(null);

  const onOpen = (val) => {
    setDetail(val);
    setShow(true)
  }

  const onClose = () => {
    setShow(false);
    setDetail(null)
  }

  const replaceUnderscore = (e) => {
    return e.replace(/[_]+/g, ' ');
  }

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  return (
    <>
      <Navigation/>

      <div className="Wallet">
        <div className="Wallet_Con">

          { account ?

            <>


              <h1
                style={{alignSelf: "center"}}>{count == null ? "LOADING..." : count < 3 ? "You need to own 3 or more Alien Fam NFTs to access the Dashboard." : count >= 3 ? "Your Alien Fam Dashboard" : "Your Alien Fam Dashboard"}</h1>

              <h2 className="wallet_address">{truncateString(account, 15)}</h2>


              {count >= 3 ?
                <div className="extra-navigation">
                  <a data-tip data-for="coming-soon">
                    <img src={shirtIcon} alt="Shirt"/> Merch
                  </a>
                  <a data-tip data-for="coming-soon">
                    <img src={airdropsIcon} alt="Shirt"/> Airdrops
                  </a>
                  <a data-tip data-for="coming-soon">
                    <img src={giveawaysIcon} alt="Shirt"/> Giveaways
                  </a>
                  <a data-tip data-for="coming-soon">
                    <img src={vipIcon} alt="Shirt"/> VIP Access
                  </a>

                  <ReactTooltip id="coming-soon" place="top" effect="solid">
                    Coming Soon
                  </ReactTooltip>

                </div>

                : '' }

              <ReactTooltip/>

              
              {count >= 3 ?

              <div className="Wallet_Pic_Con">
                {image.map((value, index) => {
                  return <div key={index} className="Wallet_Pic" onClick={() => onOpen(value)}>
                    {value?.image?.slice(-3) == ("mp4" || "avi" || "mov") ?
                      <video loop autoPlay muted src={`https://ipfs.io/ipfs/${value?.image.split("ipfs://")[1]}`}/>
                      :
                      <img src={`https://ipfs.io/ipfs/${value?.image.split("ipfs://")[1]}`} alt=""/>
                    }
                    <h2 onClick={() => console.log(value?.image?.slice(-3))}>{value?.name}</h2>
                  </div>
                })}
              </div>

            : '' }

            </>

            :

            <button
              onClick={(e) => {
                e.preventDefault();
                dispatch(connectWallet(config, abi));
              }}
            >
              CONNECT WALLET
            </button>
            
          }
        </div>
      </div>
      

      <div className={show ? "Modal Show" : "Modal"}>
        <div className="Modal_Con">
          {detail?.image?.slice(-3) == ("mp4" || "avi" || "mov") ?
            <video loop autoPlay muted src={`https://ipfs.io/ipfs/${detail?.image.split("ipfs://")[1]}`}/>
            :
            <img src={`https://ipfs.io/ipfs/${detail?.image.split("ipfs://")[1]}`} alt=""/>
          }
          <div className="Modal_Right">
            <h1>{detail?.name}</h1>
            <p>{detail?.description}</p>
            {detail?.attributes ?
              <h2>Atributes</h2>
              : null}
            {detail?.attributes?.map((value) => (
              <div className="Modal_Atr">
                <p><b>{value?.trait_type}</b>: {replaceUnderscore(value?.value)}</p>
              </div>
            ))}
          </div>
          <div className="Modal_Close" onClick={() => onClose()}>
            <h3>x</h3>
          </div>
        </div>
      </div>
    </>
  );
  
}

const vipnav = styled.div`
`


