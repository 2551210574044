import React from 'react';
import styled from 'styled-components';
import Countdown from 'react-countdown';

// Random component
const Completionist = () => {
   return (
       <MintHeroStyle>
        <div className="inner-mint-block">
            <h2>Time to Escape!</h2>
            <p>5,000 Aliens are escaping from a Top Secret Military Base. Help the Aliens Escape and blend in with humans. If you help three or more Aliens escape, you will have access to the <a href="/#/dashboard">Dashboard</a>.</p>
            <a className='green' href="/#/mint">Mint an Alien</a>
        </div>
       </MintHeroStyle>
   );
}

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
        <>
        <CountdownStyled>
            <div className="countdown-inner">
                <span>{days} <b>Days</b></span>
                <span>{hours} <b>Hours</b></span>
                <span>{minutes} <b>Minute</b></span>
                <span>{seconds} <b>Seconds</b></span>
            </div>
            <p className='countdown-inner-text'>Until Escape Day</p>
        </CountdownStyled>
        
        </>
    )
  }
};

export const CountDownClock = () => {
    return (
        // <Countdown
        //     date={new Date('2022-05-18T17:00:00')}
        //     renderer={renderer}
        // />
        
        <Countdown
            date={new Date('2022-05-18T18:00:00')}
            renderer={renderer}
        />
    )
}


const CountdownStyled = styled.div`
    background: rgba(0,0,0,0.5);
    padding: 25px;
    border-radius: 12px;
    font-family: 'Oswald', sans-serif;
    .countdown-inner {
        color: #fff;
        font-size: 50px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
        @media (max-width: 760px){
            font-size: 25px;
            max-width: 90%;
        }
        span {
            font-size: 2em;
            display: flex;
            flex-direction: column;
            text-align: center;
            line-height: normal;
            margin: 0 35px;
            text-shadow: 1px 1px 35px #000;
            color: #65ED26;
            @media (max-width: 760px){
                margin: 0 15px;
            }   
            b {
                font-size: 0.3em;
            }
        }
    }
    p {
        font-size: 50px;
        color: #fff;
        font-weight: lighter;
        margin: 25px auto 0;
        text-align: center;
        display: block;
        @media (max-width: 760px){
            font-size: 35px;
        } 
    }
`;
const MintHeroStyle = styled.div`
    background: rgba(0,0,0,0.6);
    width: 100%;
    .inner-mint-block {
        max-width: 960px;
        margin: 0 auto;
        text-align: center;
        padding: 15px;
        a {
            color: #65ED26;
            margin: 0;
        }
        p {
            max-width: 550px;
            margin: 0 auto 35px;
        }
    }
    h2 {
        font-family: 'Oswald', sans-serif;
        font-weight: 300;
        color: #fff;
        font-size: 8em;
        margin: 25px;
        background: -webkit-linear-gradient(#eee, #65ED26);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @media (max-width: 760px){
            font-size: 4em;
        }
    }
    p {
        color: #fff;
        font-size: 22px;
        margin-bottom: 35px;
    }
    a {
            color: #fff;
            text-decoration: none;
            font-weight: 400;
            transition: .3s;
            margin: 25px 0 0;
            display: inline-block;
            &:hover {
                color: #65ED26;
            }
            &.green {
                font-size: 2em;
                font-family: 'Oswald', sans-serif;
                color: #000;
                background: #65ED26;
                background: linear-gradient(-45deg, #65ED26 50%, #fff 60%, #65ED26 70%);
                background-size: 600% 100%;
                padding: 5px 25px;
                border-radius: 30px;
                border: 4px solid #2F750F;
                text-transform: uppercase;
                font-weight: 500;
                display: inline-block;
                text-align: center;
                animation: shine 20s infinite;
                svg {
                    width: 18px;
                    margin-right: 10px;
                }
                a {
                    color: #000;
                    font-weight: 500;
                }
                &:hover {
                    color: #65ED26;
                    background: #000;
                    border: 4px solid #65ED26;
                    a {
                        color: #65ED26;
                    }
                    svg {
                        fill: #65ED26;
                    }
                }
                    
                @keyframes shine {
                    0% {
                        background-position-x: 400%;
                    }
                    50% {
                        background-position-x: 0%;
                    }
                    100% {
                        background-position-x: -400%;
                    }
                }
                
            }
        }
`