import logo from '../images/alienfam-logo.png';
import heroImage from '../images/alien-pods.jpg';
import alienicon from '../images/alien-solid.svg';
import styled from 'styled-components';
import { Link } from "react-router-dom";

function Navigation() {
    return (
        <>
        <AlienHeader className="header">

            <a href="/">
                <AlienLogo src={logo} alt="Alien Fam"/>
            </a>

            <div className="connect">
                <ul>
                    <li><Link to={"/mint"}>Mint</Link></li>
                    <li>
                         <a href="https://opensea.io/collection/alienfam" target="_blank" rel="noreferrer"> 
                         Open Sea</a>
                     </li>
                     <li>
                        <a href="https://discord.com/invite/6AbcgVZMTf" target="_blank" rel="noreferrer"> 
                        Discord
                        </a>
                    </li>
                    <li><a href="https://twitter.com/AlienFamNFT" target="_blank" rel="noreferrer">Twitter</a></li>
                    
                    {/* <li>
                        <a className='green' href="https://discord.com/invite/6AbcgVZMTf" target="_blank" rel="noreferrer">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 .0001c-123.8 0-224 87.99-224 232.5c0 111.7 134.2 224.5 194.9 269.9c17.25 12.87 41 12.87 58.25 0C313.8 456.1 448 344.2 448 232.5C448 87.99 347.8 .0001 224 .0001zM176 319.1h-32c-44.12 0-80-35.87-80-79.1c0-8.874 7.125-15.1 16-15.1h32c44.12 0 80 35.87 80 79.99C192 312.9 184.9 319.1 176 319.1zM304 319.1h-32c-8.875 0-16-7.125-16-15.1c0-44.12 35.88-79.99 80-79.99h32c8.875 0 16 7.124 16 15.1C384 284.1 348.1 319.1 304 319.1z"/></svg>
                        Join The Fam
                        </a>
                    </li> */}
                    <li>
                     <Link className='green' to={"/dashboard"}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 .0001c-123.8 0-224 87.99-224 232.5c0 111.7 134.2 224.5 194.9 269.9c17.25 12.87 41 12.87 58.25 0C313.8 456.1 448 344.2 448 232.5C448 87.99 347.8 .0001 224 .0001zM176 319.1h-32c-44.12 0-80-35.87-80-79.1c0-8.874 7.125-15.1 16-15.1h32c44.12 0 80 35.87 80 79.99C192 312.9 184.9 319.1 176 319.1zM304 319.1h-32c-8.875 0-16-7.125-16-15.1c0-44.12 35.88-79.99 80-79.99h32c8.875 0 16 7.124 16 15.1C384 284.1 348.1 319.1 304 319.1z"/></svg>
                        Dashboard
                    </Link>
                    </li>
                
                </ul>
            </div>
            
        </AlienHeader>

        {/* <HeroBanner></HeroBanner> */}

        </>
    );
  }
  
  export default Navigation;

  
  const AlienLogo = styled.img`
    max-width: 226px;
  `
  const AlienHeader = styled.header`
    display: flex;
    align-items: center;
    background: #082122;
    width: 100%;
    padding: 35px;
    font-family: 'Oswald', sans-serif;
    font-size: 22px;
    @media (max-width: 940px){
        flex-direction: column;
    }
    nav {
        @media (max-width: 940px){
            display: none;
        }
    }
    ul{
        display: flex;
        list-style: none;
        padding: 0px 25px;
        align-items: center;
        li {
            margin: 0 15px;
        }
        a {
            color: #fff;
            text-decoration: none;
            font-weight: 400;
            transition: .3s;
            &:hover {
                color: #65ED26;
            }
            &.green {
                color: #000;
                background: #65ED26;
                background: linear-gradient(-45deg, #65ED26 50%, #fff 60%, #65ED26 70%);
                background-size: 600% 100%;
                padding: 5px 25px;
                border-radius: 30px;
                border: 4px solid #2F750F;
                text-transform: uppercase;
                font-weight: 500;
                display: block;
                text-align: center;
                animation: shine 20s infinite;
                svg {
                    width: 18px;
                    margin-right: 10px;
                }
                a {
                    color: #000;
                    font-weight: 500;
                }
                &:hover {
                    color: #65ED26;
                    background: #000;
                    border: 4px solid #65ED26;
                    a {
                        color: #65ED26;
                    }
                    svg {
                        fill: #65ED26;
                    }
                }
                    
                @keyframes shine {
                    0% {
                        background-position-x: 400%;
                    }
                    50% {
                        background-position-x: 0%;
                    }
                    100% {
                        background-position-x: -400%;
                    }
                }
                
            }
        }
    }
    .connect{
        display: flex;
        flex: 1;
        justify-content: flex-end;
        @media (max-width: 940px){
            ul {
                flex-direction: column;
                margin: 0;
                li {
                    margin: 5px 0;
                }
            }
        }
    }

`;
